// custom CSS styles
import "@/styles/global.css";
import "@/styles/ie11.scss";


/**
 * Scroll to top when route updates.
 * 
 * We can also access the previous/new routes, if this ever needs to be tweaked
 * to only work on certain routes.
 */
export const onPreRouteUpdate = (): void => {
  window.scrollTo(0, 0);
};